* { box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  height: 100%;
}

.full-width { width: 100%; }

.full-size { width: 100%; height: 100%; }

.non-rounded { border-radius: unset !important;  }

svg[role="img"] { display: block; }

.main-splitter > .layout-splitter { width: 20px; background-color: #e0e0e0 }

.main-splitter > .layout-splitter:after {
  position: absolute;
  color: #3f51b5;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  flex-shrink: 0;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  top: calc(50% - 20px);
  width: 50px;
  height: 58px;
}

.main-splitter.graphics > .layout-splitter:after {
  content: 'Settings';
  transform: rotate(-90deg);
}

.main-splitter.settings > .layout-splitter:after {
  content: 'Graphics';
  transform: rotate(270deg);
}
